.grey-card {
  background-color: #1a1a1a;
  padding: 1rem;
  border-radius: 1rem;
  .subtitle-text {
    font-size: 16px;
    font-weight: 400;
    color:#FFFFFF80
  }
  .w-fill {
    width: 100%;
  }

}

.abs-button-center{
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}