#mechanics-section {

      .btn-outline-btn{
    background: transparent;
    border: 1px solid #000;
    color: #000;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 64px;
    
}
  #mechanics-carousel {
    @media screen and (max-width: 991px) {
      .slider-right-tokenomics.second-container {
        height: 305px;
      }
    }
    @media screen and (max-width: 767px) {
      .slider-right-tokenomics.second-container {
        height: 250px;
      }
    }

    @media screen and (max-width: 991px) {
      .gap-4 {
        gap: 10px !important;
      }
    }

    @media screen and (max-width: 1300px) {
      .slider-right-tokenomics .overflow-slider {
        left: 0px !important;
      }
    }
  }
  .card-project {
    // background: blue;
  }
  .full-height-col {

  }

  .full-height-col {
    height: 700px;
    background-color: #222222;
    @media screen and (max-width: 1400px) {
      height: 575px;
    }
    @media screen and (max-width: 1300px) {
      height: 500px;
    }
  }
  .left-col {
    height: 342px;
    background-color: #222222;
    @media screen and (max-width: 1400px) {
      height: 280px;
    }
    @media screen and (max-width: 1300px) {
      height: 242px;
    }
  }
  .right-col {
    background: linear-gradient(45deg, #3c124f, #4d2f16);
  }

  .full-height {
    height: 700px;
    background-color: #222222;
  }
  .font-size-32 {
    @media screen and (max-width: 1400px) {
      font-size: 21px !important;
    }
    @media screen and (max-width: 991px) {
      font-size: 28px !important;
    }
  }
  .font-size-48 {
    @media screen and (max-width: 1500px) {
      font-size:40px !important;
    }
    @media screen and (max-width: 1400px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 1400px) {
      font-size: 32px !important;
    }
    @media screen and (max-width: 1300px) {
      font-size: 28px !important;
    }
  }

  .font-size-18 {
    @media screen and (max-width: 1400px) {
      font-size: 13px !important;
    }
  }

  @media screen and (max-width: 991px) {
    .full-height {
      height: 600px;
    }
  }
  .left-col {
    .first-child {
      width: 300px;
    }
    @media screen and (max-width: 400px) {
      .first-child {
        width: 250px;
      }
    }
  }
}
