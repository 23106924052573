@import "./colors.scss";
@import "./fontFamily.scss";
@import "./fontSize.scss";
@import "./containers.scss";
@import "./buttons";
@import "./customSlider";

.App {
  background-color: #000000;

  // ======================================================= React Toastify ==============================================
  .Toastify__toast-theme--light {
    background-color: #061753;
    border: 1px solid #023aff;
    border-radius: 12px;
    color: #ffffff;
  }

  .Toastify__close-button {
    color: white;
    opacity: 1 !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.white-color {
  color: #ffffff;
}

.section-padding {
  padding: 4rem 0;

  @media screen and (max-width: 991px) {
    padding: 3rem 0;
  }
}

// ========================================================= text colors ========================================

.light-grey {
  color: $light-grey;
}

.gradient-txt {
  background: $zixxar-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

//===============================================================================================================

.line-height-1-7 {
  line-height: 1.7;
}
.line-height-1-5 {
  line-height: 1.5;
}
.line-height-1-4 {
  line-height: 1.4;
}
.line-height-1-3 {
  line-height: 1.3;
}
.line-height-1-2 {
  line-height: 1.2;
}
.line-height-1-1 {
  line-height: 1.1;
}

.cursor-pointer {
  cursor: pointer;
}

.more-than-sales {
  display: flex;
  height: 100vh;

  .large-container {
    height: auto;
  }
}
.line-height-1 {
  line-height: 1;
}
#make-a-difference {
  position: relative;
  background: #000;

  &:before {
    display: block;
    content: "";
    position: absolute;
    top: -200px;
    left: 0;
    height: 200px;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(255, 176, 0, 0) 100%
    );
  }

  @media screen and (max-width: 991px) {
    &::before {
      display: none;
    }
  }
}

.register-section,
.footer {
  position: relative;
}

.font-size-54 {
  font-size: 54px;
  letter-spacing: -1.5px;
}

.headline-animation {
  transition: all 0.5s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(50%);

  @media screen and (max-width: 1450px) {
    font-size: 40px !important;
  }
  @media screen and (max-width: 1200px) {
    font-size: 36px !important;
  }
}

.headline-animation.active {
  opacity: 1;
  transform: none;
}

.heading-holder {
  width: 100%;
  height: 54px;
}

@media screen and (max-height: 900px) and (max-width: 767px) {
  .animate-section1,
  .animate-section2,
  #make-a-difference:before {
    display: none;
  }
  .more-than-sales {
    height: auto;
    position: relative !important;
    top: auto !important;
    padding-top: 0;
  }
}
#join-zixxar {
  position: relative;
  background: #000;
}

@-moz-document url-prefix() {
  .more-than-sales {
    height: 100%;
  }
}
@media screen and (max-width: 1500px) {
  .img-lg-fluid {
    max-width: 100%;
    height: auto;
  }
}
.font-size-48 {
  font-size: 48px;
  letter-spacing: -1.5px;
  @media screen and (max-width: 1400px) {
    & {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 991px) {
    & {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 767px) {
    & {
      font-size: 28px;
    }
  }
}

.gradient-pot {
  background: $livepot-gradient;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.background-lipo-gradient {
  background: linear-gradient(45deg, #BC7738, #9130BB)!important;
}
.homepage {
  overflow: hidden;
}
.radius64 {
  border-radius: 64px;
}
.radius16 {
  border-radius: 16px;
}
.z-index {
  z-index: 99;
}
.mw-1500-580{
  @media screen and (max-width:1500px) {
    max-width: 580px;
    
  }
}
.mw-586{
  max-width: 586px;
}
.color-gradient-lipo {
  background: -webkit-linear-gradient(45deg, #ff9c42 0%, #c236ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  // background: -webkit-linear-gradient(#eee, #333);
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;
}
.color-gradient-blue {
  background: transparent linear-gradient(45deg, #73fc48 0%, #369aff 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color-gradient-purple {
  background: transparent linear-gradient(45deg, #42ff99 0%, #ab6eff 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lh-normal {
  line-height: normal;
}
.rounded-24 {
  border-radius: 24px !important;
}
.blue-color {
  color: #67e8f9;
}
.green-color-gradient {
  background: transparent linear-gradient(45deg, #67e8f9 0%, #00ff58 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.yellow-color-gradient {
  background: transparent linear-gradient(45deg, #ff9c42 0%, #e0ff36 100%) 0% 0%
    no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.lipo-purple {
  color: #c439f8;
}
.purple-color {
  color: #9687f0;
}
.purple-grey-color {
  color: #a890a5;
}
.yellow-color{
  color:#EAD251
}
.grey-background{
  background: #222222
}
.font-size-12{
  font-size: 12px;
}

.mh-mob-img{
  @media screen and (max-width: 991px) {
    max-height: 350px;
  
    
  
  max-height: 350px;
}}
.ps-1500-0{
  @media screen and (max-width:1500px) and (min-width: 991px) {
    
  }
  padding-left: 0!important;
}