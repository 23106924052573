#first-project-section {

 
  .txt-container {
    max-width: 25%;
    margin: auto;

    @media screen and (max-width: 991px) {
      margin-top: 0rem;
      margin-bottom: 10px !important;
    }
  }

  .mobile-d-none {
    @media screen and (max-width: 1500px) {
      display: none;
    }
  }

  .success-buseiness-card-img {
    @media screen and (max-width: 567px) {
      max-width: 105px;
    }
  }

  .img-sizer {
    height: 55%;
    @media screen and (max-width: 1400px) {
      height: 45%;
    }
    @media screen and (max-width: 1099px) {
      height: 60%;
    }
    @media screen and (max-width: 991px) {
      height: 60%;
    }
    @media screen and (max-width: 767px) {
      height: 50%;
    }
    @media screen and (max-width: 567px) {
      height: 35%;
    }
  }
  .font-size-24 {
    @media screen and (max-width: 1501px) and (min-width: 991px) {
      font-size: 23px;
    }
  }
  .section-padding{
    @media screen and (max-width: 511px) {
      padding-bottom: 0px;
    }
  }

  .success-buseiness-card {
    background-color: #222;
    height: 380px;
    width: 25%;
    min-width: 302px;
    max-width: 302px;
    margin: 0 10px;
    padding: 1.5rem;
    border-radius: 16px;
    position: relative;
    @media screen and (max-width: 991px) {
      min-width: 350px;

      margin: 0 5px;
      height: 380px;
    
    }
    @media screen and (max-width: 511px) {
   
      height: 350px;
      
    
    }


  

  
    // @media screen and (max-width: 1300px) {
    //   height: 500px;
    //   width: 364px;
    // }

 
    

    &-img {
      // position: absolute;
      // top: -50px;
      width: 170px;
    }

    &-txt {
      // padding-top: 7rem;

      @media screen and (max-width: 567px) {
        padding-top: 2rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
      }
    }
  }
  .single-player-card{
    @media screen and (max-width: 991px) {
 
        margin-left: 10px;
   
    }
  }
  .negative-margin {
    margin-top: -100px;
  }
}
