// ================================================ 14 ===============================================
.font-size-14 {
  font-size: 14px;

  @media screen and (max-width: 767px) {
    font-size: 12px;
  }

  @media screen and (max-width: 575px) {
    font-size: 11px;
  }
}

// ================================================== 16 =========================================
.font-size-16 {
  font-size: 16px;

  // @media screen and (max-width: 1300px) {
  //   font-size: 14px;
  // }
  @media screen and (max-width: 991px) {
    font-size: 15px;
  }

  @media screen and (max-width: 575px) {
    font-size: 15px;
  }

  @media screen and (max-width: 320px) {
    font-size: 11px;
  }
}

// ================================================ 18 ============================================
.font-size-18 {
  font-size: 18px;

  @media screen and (max-width: 1024px) {
    font-size: 17px;
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 15px;
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
  }

  @media screen and (max-width: 320px) {
    font-size: 13px;
  }
}

// ============================================= 20 ======================================================
.font-size-20 {
  font-size: 20px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 991px) {
    font-size: 16px;
  }

  @media screen and (max-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 575px) {
    font-size: 12px;
  }
}

.font-size-22 {
  font-size: 22px;

  @media screen and (max-width: 1024px) {
    font-size: 20px;
  }

  @media screen and (max-width: 991px) {
    font-size: 18px;
  }

  @media screen and (max-width: 767px) {
    font-size: 16px;
  }

  @media screen and (max-width: 575px) {
    font-size: 14px;
  }
}

// ======================================================== 24 ====================================================
.font-size-24 {
  font-size: 24px;
  letter-spacing: -1px;



 

  @media screen and (max-width: 575px) {
    font-size: 22px;
  }

  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
}

// ===================================================== 28 =========================================================

.font-size-28 {
  font-size: 28px;

  @media screen and (max-width: 1024px) {
    font-size: 26px;
  }

  @media screen and (max-width: 991px) {
    font-size: 24px;
  }

  @media screen and (max-width: 767px) {
    font-size: 20px;
  }

  @media screen and (max-width: 575px) {
    font-size: 18px;
  }

  @media screen and (max-width: 320px) {
    font-size: 16px;
  }
}

// ========================================================== 32 =======================================================
.font-size-32 {
  font-size: 32px;
  letter-spacing: -1px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }

  @media screen and (max-width: 767px) {
    font-size: 26px;
  }

  @media screen and (max-width: 575px) {
    font-size: 24px;
  }

  @media screen and (max-width: 320px) {
    font-size: 20px;
  }
}

// ========================================================== 32 =======================================================
.font-size-36 {
  font-size: 36px;
  letter-spacing: -1px;

  @media screen and (max-width: 1024px) {
    font-size: 34px;
  }

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767px) {
    font-size: 30px;
  }

  @media screen and (max-width: 575px) {
    font-size: 28px;
  }

  @media screen and (max-width: 320px) {
    font-size: 26px;
  }
}

// ============================================================== 40 =======================================================

.font-size-40 {
  font-size: 40px;
  letter-spacing: -1.5px;

  @media screen and (max-width: 1024px) {
    font-size: 36px;
  }

  @media screen and (max-width: 991px) {
    font-size: 32px;
  }

  @media screen and (max-width: 767px) {
    font-size: 28px;
  }

  @media screen and (max-width: 575px) {
    font-size: 26px;
  }

  @media screen and (max-width: 320px) {
    font-size: 22px;
  }
}

// ============================================================== 56 =======================================================

.font-size-56 {
  font-size: 56px;
  letter-spacing: -2px;

  @media screen and (max-width: 1024px) {
    font-size: 50px;
  }

  @media screen and (max-width: 991px) {
    font-size: 48px;
  }

  @media screen and (max-width: 767px) {
    font-size: 40px;
  }

  @media screen and (max-width: 575px) {
    font-size: 32px;
  }

  @media screen and (max-width: 320px) {
    font-size: 26px;
  }
}

// ============================================================== 64 =======================================================

.font-size-64 {
  font-size: 64px;

  @media screen and (max-width: 1400px) {
    font-size: 60px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 58px;
  }

  @media screen and (max-width: 991px) {
    font-size: 52px;
  }

  @media screen and (max-width: 767px) {
    font-size: 46px;
  }

  @media screen and (max-width: 575px) {
    font-size: 38px;
  }

  @media screen and (max-width: 320px) {
    font-size: 32px;
  }
}

.font-size-80 {
  font-size: 80px;
  letter-spacing: -2px;

  @media screen and (max-width: 1500px) {
    font-size: 50px;
  }

  @media screen and (max-width: 1135px) {
   
  }

  @media screen and (max-width: 1068px) {
    font-size: 45px;
  }
  @media screen and (max-width: 991px) {
    font-size: 40px;
  }
  

  @media screen and (max-width: 767px) {
    font-size: 40px;
  }

  @media screen and (max-width: 575px) {
    font-size: 28px;
  }

  @media screen and (max-width: 400px) {
    font-size: 32px;
  }
  @media screen and (max-width: 345px) {
    font-size: 25px;
  }
}
