#platform-for-comunity {
    .card {

        padding: 1rem;
        border-radius: 1rem;
        height: 300px;
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        @media (max-width: 768px) {
            height: 260px;
            margin-bottom: 2rem;
            margin-top: 0rem;
        }
        .card-title {

            color: #1A1A1A;
            margin-top: 6rem;
        }
        .card-text {
            color: #00000080;
        }
    }
    .card-1 {
        background: linear-gradient(45deg, #FF9C42 0%, #C236FF 100%);
    }
    .card-2 {
        background: linear-gradient(45deg, #E0FF36 0%, #FF9C42 100%);
    }
    .btn-outline-btn{
        background: transparent;
        border: 1px solid #000;
        color: #000;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        border-radius: 64px;
        
    }
    .mw-300{
        max-width: 330px;
    }
    .abs-image{
        position: absolute;
        right: 10px;
        top: -35%;
        @media (max-width: 768px) {
            top: -20%;
            left: 50%;
            width: 164px;
            transform: translateX(-50%);
        }
    }
}
